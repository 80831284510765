import React, { useContext, useState } from 'react';
import { ChatContext, MessageActions, MessageLivestream } from 'stream-chat-react';

import { LiveEventReactions } from '../LiveEventReactions/LiveEventReactions';

import { OnlineIndicator } from '../../assets/OnlineIndicator';
import { PinIcon } from '../../assets/PinIcon';
import { PinIconDark } from '../../assets/PinIconDark';

import './LiveEventMessage.css';

export const LiveEventMessage = (props) => {
  const { message, pinnedMessagesIds, setPinnedMessages } = props;
  const isMessagePinned = pinnedMessagesIds?.find((id) => id === message.id);
  const [isPinned, setIsPinned] = useState(isMessagePinned);

  const { theme } = useContext(ChatContext);

  const onlineStatus = props.message.user.id === 'avatar-robert';
  //console.log(props.client.user);

  // const MyUserAvatar = (props) => {
  //   if (isMyMessage()) {
  //     return <Avatar {...props} />;
  //   }
  //   return <Avatar {...props} />;
  // };

  const pinChecker = () => {
    if (!isPinned) {
      setIsPinned(true);
      setPinnedMessages((prevMessages) => ({
        ...prevMessages,
        [message.id]: message,
      }));
    } else {
      setIsPinned(false);
      setPinnedMessages((prevMessages) => {
        const copy = { ...prevMessages };
        delete copy[message.id];
        return copy;
      });
    }
  };

  const getMessageActions = () => {
    //console.log(props.members);
    let user_id = props.client.user.id;
    let is_moderator = false;
    //console.log(user_id);
    if ( props.hasOwnProperty('members') && props.members.hasOwnProperty(user_id) && props.members[user_id].role === 'moderator' ){
      is_moderator = true;
    }
    if ( props.isMyMessage() || props.client.user.role === 'admin' || is_moderator ) {
      return ['edit', 'delete', 'react', 'reply'];
    }
    return ['react', 'reply'];
  };

  return (
    <div className={isPinned ? 'live-event-message__container__pinned' : 'live-event-message__container__unpinned'}>
      <div className='pin-icon' style={{ marginLeft: '15px' }} onClick={pinChecker}>
        {isPinned ? theme === 'livestream light' ? <PinIcon /> : <PinIconDark /> : null}
      </div>
      <div style={{ position: 'relative' }}>
        {onlineStatus && (
          <div className='online-indicator'>
            <OnlineIndicator />
          </div>
        )}
        <div className='new-actions'>
          <MessageActions {...props} handleMute={pinChecker} getMessageActions={getMessageActions} />
        </div>
        <MessageLivestream {...props} ReactionsList={LiveEventReactions} />
      </div>
    </div>
  );
};
