import React, { useState } from 'react';
import './LiveEventChannelSwitch.css';

//import { LiveChat } from '../../assets/LiveChat';
//import { Pins } from '../../assets/Pins';
//import { Attendees } from '../../assets/Attendees';
//import { LiveChatBlue } from '../../assets/LiveChatBlue';
//import { PinsBlue } from '../../assets/PinsBlue';
//import { AttendeesBlue } from '../../assets/AttendeesBlue';
import { LiveEventChannelContainer } from '../LiveEventChannelContainer/LiveEventChannelContainer';

export const LiveEventChannelSwitch = () => {
  const [tab] = useState(1);
  return (
    <>
      <LiveEventChannelContainer tab={tab} />
    </>
  );
};
