import React from 'react';

export const OnlineIndicator = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="5.84623"
      cy="5.84615"
      r="4.65385"
      fill="#20E070"
      stroke="white"
    />
  </svg>
);
