/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, Window, Thread, Streami18n, enTranslations } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';

import './App.css';

import { LiveEventChannelFooter } from './components/LiveEventChannelFooter/LiveEventChannelFooter';
import { LiveEventChannelSwitch } from './components/LiveEventChannelSwitch/LiveEventChannelSwitch';
import { LiveEventMessage } from './components/LiveEventMessage/LiveEventMessage';
//import { LiveEventPanelists } from './components/LiveEventPanelists/LiveEventPanelists';
//import { LiveEventWindowControls } from './components/LiveEventWindowControls/LiveEventWindowControls';
//import { LiveVideoIcon } from './assets/LiveVideoIcon';
//import { getRandomImage } from './assets/getRandomImage';

const urlParams = new URLSearchParams(window.location.search);
const apiKey = urlParams.get('apikey') || process.env.REACT_APP_STREAM_KEY;
const channelName = urlParams.get('channel') || 'fair';
const userName = urlParams.get('name') || 'Besökare';
const userId = urlParams.get('user') || "sportfiske";
//const admin = urlParams.get('admin') || false;
const adminToken = urlParams.get('token') || false;
//const userToken = urlParams.get('user_token') || process.env.REACT_APP_USER_TOKEN;
//const targetOrigin = urlParams.get('target_origin') || process.env.REACT_APP_TARGET_ORIGIN;

const i18nInstance = new Streami18n({
  language: 'en',
  translationsForLanguage: {
    ...enTranslations,
    Mute: 'Pin message',
    Unmute: 'Unpin message',
  },
});

let chatClient = StreamChat.getInstance(apiKey);

const App = () => {
  const [channel, setChannel] = useState(null);
  const [currentTheme, setCurrentTheme] = useState('light');

  useEffect(() => {
    const getChannel = async () => {
      //let member;
      if ( adminToken ){
        await chatClient.connectUser({
          id: userId,
          role: 'admin',
          name: userName
          //image: 'https://getstream.io/random_svg/?name=Mikael'
        }, adminToken);
        //member = userName;
      }else {
        //await chatClient.setGuestUser({ id: userId, name: userName, image: getRandomImage() });
        await chatClient.connectAnonymousUser();
        //localStorage.setItem('stream-guest-id', selection)
        //member = t.me.id;
        //console.log(t);

      }

      const newChannel = await chatClient.channel('livestream', channelName, {
        name: 'Virtual Event',
      });

      //await newChannel.create();

      //if (!newChannel.state.members[member]) await newChannel.addMembers([member]);

      await newChannel.watch();
      setChannel(newChannel);
    };

    getChannel();

    //return () => chatClient.disconnectUser();
  }, []);

  useEffect(() => {
    const handleThemeChange = (e) => {
      if (e === 'light' || e === 'dark') {
        setCurrentTheme(e);
      }
    };

    window.addEventListener('message', (e) => handleThemeChange(e.data));
    return () => window.removeEventListener('message', (e) => handleThemeChange(e.data));
  }, []);

  //useChecklist(chatClient, targetOrigin);

  return (
    <>
      {/* <LiveEventWindowControls currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} /> */}
      <div className='main-container'>
        <Chat client={chatClient} i18nInstance={i18nInstance} theme={`livestream ${currentTheme}`}>
          <div className='main-container-inner'>
            <div className='main-container-inner__right'>
              {channel && (
                <Channel channel={channel}>
                  <Window hideOnThread>
                    <LiveEventChannelSwitch />
                  </Window>
                  <Thread
                    additionalMessageListProps={{ TypingIndicator: () => null }}
                    Message={LiveEventMessage}
                    MessageInput={LiveEventChannelFooter}
                  />
                </Channel>
              )}
            </div>
          </div>
        </Chat>
      </div>
    </>
  );
};

export default App;
